<div class="container">
    <mat-toolbar>
        <span class="spacer"></span>
        <span class="text">
            <a href="/" *ngIf="authState !== 'signedin'">Roberto Villalobos Jr.</a>
            <a routerLink="/demo" *ngIf="authState == 'signedin'">Roberto Villalobos Jr.</a>
        </span>
        <span class="spacer"></span>
        <span class="text">
            <a mat-button href="#header-section" *ngIf="authState !== 'signedin'">Home</a>
            <a mat-button routerLink="/demo" *ngIf="authState == 'signedin'" data-test="home">Home</a>
        </span>
        <span class="text"><a mat-button *ngIf="authState !== 'signedin'" href="#about-section">About</a></span>
        <span class="text"><a mat-button *ngIf="authState !== 'signedin'" href="#work-section">My-Work</a></span>
        <span class="text"><button mat-button *ngIf="authState !== 'signedin'" (click)="onClickSideNav('login')" style="width: 30px;" data-test="signin">Log In</button></span>
        <span class="text"><button mat-button *ngIf="authState == 'signedin'" (click)="logOut()" style="width: 30px;" data-test="logout">Log Out</button></span>
        <span class="spacer"></span>
    </mat-toolbar>
        
    <mat-drawer-container class="nav-container" autosize>
        <mat-drawer #drawer class="sidenav" mode="over" position="end">
            <amplify-authenticator *ngIf="authState !== 'signedin'" usernameAlias="email">
                <amplify-sign-up
                    slot="sign-up"
                    usernameAlias="email"
                    [formFields]="formFields"
                ></amplify-sign-up>
                <amplify-confirm-sign-up
                    slot="confirm-sign-up"
                ></amplify-confirm-sign-up>
                <amplify-sign-in slot="sign-in" usernameAlias="email"></amplify-sign-in>
            </amplify-authenticator>
        </mat-drawer>
        
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-container>
</div>



