<div id="about-section">
    <div class="container">
        <div>
            <h1>About Me</h1>
        </div>
        <div class="description">
            <div>
                <img class="img-circle" src="../assets/img/team/team3.jpg" alt="">
                <h4><strong>Roberto Villalobos Jr.</strong> </h4>
                <p>
                    Full Stack Software Developer
                </p>
                <p>
                    <a href="http://www.linkedin.com/in/rvillalobositprofessional" target="_blank">
                        <i class="fa fa-linkedin-square fa-2x color-linkedin"></i>
                    </a>
                    &nbsp;
                    <a href="https://github.com/villalobosvfs" target="_blank">
                        <i class="fa fa-github fa-2x color-github"></i>
                    </a>
                </p>
            </div>
            <div>
                <h3>Over 20 years of Experience in IT</h3>
                <p>
                    I have worked with a wide variety of technologies and am continuously learning new skills. I have worked for both manufacturing and 
                    retail companies building or modifying applications to increase company productivity. Some of the current technologies that I am working 
                    with are serverless applications utilizing Amazon Web Services and Docker Containers. I have worked in companies using Agile 
                    methodology to quickly deliver results while maintaining quality.
                </p>
                <p>
                    Having worked in IT for over 20 years. I worked in different departments like Infrastructure and Help Desk along with 
                    Software Development. I bring a varied skillset having the experience of troubleshooting computer hardware problems, installing operating systems and talking a 
                    user through fixing network connectivity issue while not being able to see what they are seeing.
                </p>
                <h3>Skills</h3>
                <div class="skills">
                    <ul>
                        <li>Programming Languages
                            <ul>
                                <li>C Sharp</li>
                                <li>VB.Net</li>
                                <li>SQL</li>
                                <li>Javascript</li>
                                <li>Python</li>
                                <li>VB 6.0</li>
                            </ul>
                        </li>
                        <li>.Net Framework 4.x
                            <ul>
                                <li>ASP.Net</li>
                                <li>Windows Presentation Foundation</li>
                                <li>Windows Forms Application</li>
                                <li>Console Application</li>
                            </ul>
                        </li>
                        <li>Angular Web Framework
                            <ul>
                                <li>Angular Material</li>
                                <li>NodeJS</li>
                                <li>Cascading Style Sheets</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>Amazon Web Services
                            <ul>
                                <li>DynamoDB</li>
                                <li>API Gateway</li>
                                <li>Lambda</li>
                                <li>S3/Simple Storage Services</li>
                                <li>Cognito Authentication</li>
                                <li>Amplify
                                    <ul>
                                        <li>I have used Amplify to quickly build serverless applications utilizing AppSync/GraphQL, CI/CD with Code Commit and 
                                            Cypress End to End testing</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Operating Systems
                            <ul>
                                <li>Windows Server 2012, 2008, 2003, 2000, NT 4.0</li>
                                <li>Windows 10, 7, XP, 95, 3.11/MSDOS</li>
                                <li>Linux Ubuntu, Redhat</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
