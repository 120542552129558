import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-entry',
  templateUrl: './work-entry.component.html',
  styleUrls: ['./work-entry.component.css']
})
export class WorkEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
