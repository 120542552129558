<br>
<br>
<div class="container">
    <mat-card class="card">
        <mat-card-header>
          <mat-card-title>County Land Data</mat-card-title>
          <mat-card-subtitle>Serverless Application</mat-card-subtitle>
        </mat-card-header>
        <a routerLink="/landdata">
            <img mat-card-image src="../../../assets/img/countylanddata.jpg" alt="Image of County Land Data Application">
        </a>
        <mat-card-content>
          <p>
            This application utilizes AWS DynamoDB, GraphQL API, Angular Material to provide an interface for the customer to view and 
            manage judgement and deed information. The data is gathered and updated using Docker containers with Ubuntu image and Python. 
            The Docker containers are scheduled and run using AWS Elastic Container Service and Fargate.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button routerLink="/landdata" data-test="landdata">OPEN</button>
        </mat-card-actions>
      </mat-card>
      <mat-card class="card">
        <mat-card-header>
          <mat-card-title>Sudoku Game</mat-card-title>
          <mat-card-subtitle>Serverless Application</mat-card-subtitle>
        </mat-card-header>
        <a routerLink="/sudoku">
            <img mat-card-image src="../../../assets/img/Sudoku.jpg" alt="Image of County Land Data Application">
        </a>
        <mat-card-content>
          <p>
            A Sudoku puzzle game I built using Angular and Javascript. This application randomly generates the Sudoku puzzle each time the New Game button is clicked.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button routerLink="/sudoku" data-test="sudoku">OPEN</button>
        </mat-card-actions>
      </mat-card>      
</div>
