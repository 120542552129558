<div id="header-section">
    <div class="container">
        <h1><strong>Roberto Villalobos Jr.</strong></h1>
        <br /> <br /> <br />
        <h2> Software Developer</h2>
        <br />
        <a href="#about-section">  <i class="fa fa-angle-double-down fa-5x down-icon"></i> </a>
    </div>
</div>

<app-about></app-about>

<app-work-list></app-work-list>


<app-footer></app-footer>