import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoComponent } from './pages/demo/demo.component';
import { MainComponent } from './pages/main/main.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'demo', component: DemoComponent, canActivate: [AuthGuardService]},
  { path: 'landdata', loadChildren: () => import('./modules/countylanddata/countylanddata.module').then(m => m.CountylanddataModule) },
  { path: 'sudoku', loadChildren: () => import('./modules/sudoku/sudoku.module').then(m => m.SudokuModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
