import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url.toString().toLowerCase().substr(1);

    return new Observable(obs => {
      from(
        Auth.currentAuthenticatedUser({ bypassCache: false})
        .then(u => {
          obs.next(true);
        })
        .catch(() => {
          this.router.navigate(['/']);
          obs.next(false);
        })
      );
    });
  }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.canActivate(route, state);
  }
}