import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {

  constructor(private router: Router, private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  onClick(event) {
    this.ngZone.run(() => {
      this.router.navigate([event]);
    });
  }
}
