/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3f879046-5f61-46e8-bf47-7dbe80b9bd23",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_l780KkMP8",
    "aws_user_pools_web_client_id": "343m5hkp59bmqahnq0q4vfinvi",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://24nzfiezqvd4liqqpiqpkgb5bu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
