<div id="work-section" >
    <div class="container">
        <div class="title">
            <h1>Projects List</h1>
        </div>
        <div>
            <h3>Resume Demonstration Applications</h3>
            <p>
                I have added on to the tool bar at the top a 'Log In' button to demonstrate the usage of AWS Cognito User Sign Up and Sign In. Click on it and 
                register. It will send you a confirmation email with a code to enter. Once that is completed you can log in and see and test out applications that 
                I have built. These will give you an idea of my skill set. This website is hosted on AWS using Amplify and Angular.
            </p>
            <hr>
        </div>
        <div>
            <h3>County Records Application</h3>
            <p>
                Designed and Built an online application to gather data from county records and correlate them based on names using fuzzy logic.
                This application is hosted on Amazon Web Services using AWS Amplify.
                The User Interface was built using Angular, Angular Material and Javascript. It uses AWS Cognito Log In and Authorization. The user can log in and 
                view the information, mark those records with various status options sort by the various columns and export to Excel.
                The backend is using Appsync/Graphql API along with DynamoDb to store the information gathered.
                ECS Fargate is used to start up Docker Containers on a daily schedule that utilize Ubuntu Linux and Python scripts to gather data and write them to 
                the DynamoDB database or S3 bucket as needed.
                I can demonstrate this application in an interview. It is hosted on my account.
            </p>
            <hr>
        </div>
        <div>
            <h3>Retail Point of Sales</h3>
            <p>
                Redesigned the receipt printing for a large retail chain to comply with the new regulatory requirements mandated by Italy.
                Italy mandated that all retail companies use a Fiscal Printer. The difference between a Fiscal Printer and a normal printer is the 
                Fiscal Printer records the transactions for reporting to the Italian government. On a normal receipt printer all that is sent to the 
                printer is text and formatting commands and a print command is sent. The Fiscal Printer has limited formatting and specific commands to be used, 
                like a Begin Transaction command and an End Transaction. The Fiscal Printer has commands to record each transaction that must be called for 
                each line item to record the item information like item number, description, amount, tax and tax type.
            </p>
            <hr>
        </div>
    </div>
</div>