import { Component, ViewChild, ChangeDetectorRef, OnInit, OnDestroy, NgZone } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { FormFieldTypes } from '@aws-amplify/ui-components';
import { MatDrawer } from '@angular/material/sidenav/drawer'
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') private sidenav: MatDrawer;
  user: CognitoUserInterface | undefined;
  formFields: FormFieldTypes | undefined;
  authState: AuthState;
  title = 'resume';
  nav = '';

  constructor(private ref: ChangeDetectorRef, private router: Router, private ngZone: NgZone) {
    this.formFields = [
      {
        type: 'email',
        label: 'Email Address  *',
        placeholder: 'Email',
        required: true,
      },
      {
        type: 'password',
        label: 'Password *',
        placeholder: 'Password',
        required: true,
      }
    ]
  }

  ngOnInit() {

    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;

      if (authState == 'signedin') {
        if (this.sidenav.opened) {
          this.sidenav.toggle();
        }

        this.ngZone.run(() => {
          this.router.navigate(['/demo']);
        });
      } else {
        this.ngZone.run(() => {
          this.router.navigate(['/']);
        });
      }

      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  onClickSideNav(event) {
    this.nav = event;
    this.sidenav.toggle();
  }

  async logOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
